import React from 'react'
import Layout from '../components/Layout'
import CallBackBanner from '../components/CallBackBanner'
import CarouselDrag from '../components/CarouselDrag'
import TaxCreditHeader from '../components/Header/TaxCreditHeader'
import GreenCardsTaxCredit from '../components/GreenCards/GreenCardsTaxCredit'
import PromotionBanner from '../components/PromotionBanner/PromotionBanner'
import WhatIsResearch from '../components/WhatIsResearch/WhatIsResearch'
import ProjectsThatCount from '../components/ProjectsThatCount/ProjectsThatCount'
import IconsGrid from '../components/IconsGrid/IconsGrid'
import QualifyingCarousel from '../components/QualifyingCarousel/QualifyingCarousel'
import ImageAndCardTaxCredit from '../components/ImageAndCard/ImageAndCardTaxCredit'
import imageandcard from '../images/tax-credit/team_meeting_graph.svg'
import Faq from '../components/shared/FAQ/Faq'
import GeneralEnquiryForm from '../components/shared/Form/GeneralEnquiryForm'
import { qs } from './claims-process'
import TextCols from '../components/TextCols/TextCols'

const liContent = [
  {
    li: 'A project must seek to achieve an advance in science or technology',
    p:
      'The activities that directly contribute to achieving this advance are R&D.',
  },
  {
    li: 'The project can be a new creation or an improvement',
    p:
      'Improving existing processes or products, as well as creating new ones, involves R&D.',
  },
  {
    li: 'Failure can still count as R&D',
    p:
      'Even if the project doesn’t succeed or isn’t fully realised, R&D still takes place.',
  },
]
const TaxCredit = () => {
  return (
    <Layout
      activeLink="/tax-credit"
      title="R&D Tax Credit"
      description="R&D Tax Credit"
    >
      <TaxCreditHeader />
      {/* <PostList posts={posts} title="Latest posts" /> */}
      {/* <Pagination pageContext={pageContext} pathPrefix="/" /> */}
      <WhatIsResearch
        tag="WHAT IS RESEARCH AND DEVELOPMENT?"
        heading="Solving unusual business problems."
        body={[
          'Imagination. Innovation. Iteration.',
          'At RDA, we know these are the key ingredients to research & development. But how you explain what you are doing is sometimes as hard as doing it in the first place. That’s where we can help. We specialise in achieving Research and Development Tax Credits for businesses across all industry sectors.',
          'Our unique, one-to-one consultancy approach to client claims delivers the best possible outcome for enterprises of any size – from SMEs to large corporates. A successful claim will generate vital supplementary cash flows for your firm, genuinely reflecting and rewarding the effort and ingenuity you have put into solving unusual business problems.',
          'We work for you directly or, if you would prefer, we also offer the specialist support your accountancy firm or tax advisor often needs when preparing and submitting complicated R&D claims. With the support of our claims expertise and our trusted relationships within the relevant department at HMRC, your R&D is conveyed in the best possible light.',
          'All this is why RDA are trusted by firms ranging in turnover from £1million to £2 billion to support their innovation and handle their specialist R&D claims submissions, year after year.',
        ]}
        maxwidth="893px"
      />
      <GreenCardsTaxCredit />
      <TextCols
        heading="We can help you identify your R&D"
        leftCol={
          <div>
            <p>
              No one knows your R&D like you do, but sometimes it is tough to
              explain. That is why at RDA, we consider ourselves not
              only to be tax experts — but communicators too.
            </p>
            <p>
              Your tax-relief submission cannot be a simple matter of
              form-filling. Only by truly understanding the projects you
              undertake can the true value of your R&D be conveyed in the
              language HMRC understands. That is why we pride ourselves on our
              unique, one-to-one consultancy approach.
            </p>
          </div>
        }
        rightCol={
          <div>
            <p>
              In addition to the preparation and submission of your R&D Tax
              Credits Scheme claim, with RDA, you will have access to:
            </p>
            <ul>
              <li>a dedicated Client Manager on call</li>
              <li>
                plain-English bulletins on all government R&D regulations and
                directives{' '}
              </li>
              <li>a confidential R&D record-keeping service</li>
              <li>R&D best-practice coaching and workshops</li>
              <li>Intellectual-property assessment and advocacy</li>
            </ul>
            <p>
              The purpose of HMRC’s R&D Tax Credits Scheme is to stimulate
              investment in research and development in the UK economy. We know
              your R&D is continuously evolving. That is why we maintain an
              ongoing, year-round relationship with our partners to work not
              only on their tax-relief claim but also to help facilitate their
              R&D itself.
            </p>
            <p>
              With RDA on hand, you will maximise the returns you get
              both from your tax-relief application and from your finished R&D.
            </p>
            <p>
              Develop <i>together</i>. It’s the RDA Way
            </p>
          </div>
        }
      />
      <ProjectsThatCount
        tag="PROJECTS THAT COUNT AS R&D"
        heading="R&D tax relief can apply to any company in any sector"
        liContent={liContent}
      />
      <QualifyingCarousel id="qualifying-carousel-tax-credit" />
      <ImageAndCardTaxCredit
        image={imageandcard}
        altImg="Meeting presentation graph"
        tag="How much is R&D tax credit claim worth?"
        heading="R&D tax credits are calculated based on your R&D spend"
        content={[
          'Lots can qualify for R&D tax relief. Work you do in planning, designing, testing, analysing, adapting or creating new products or processes can all count.',
        ]}
        list={[
          'Corporation Tax reduction if you are profit-making',
          'Cash credit if you are loss-making',
          'Or a combination of the two',
        ]}
        ctaText1="Make a claim"
        ctaText2="Request a call back"
      />
      <IconsGrid />
      <PromotionBanner />
      <Faq questions={qs} />
      <CarouselDrag id="carousel-tax-credit" />
      <GeneralEnquiryForm />
      <CallBackBanner />
    </Layout>
  )
}

export default TaxCredit
