import React from 'react'
import styled from 'styled-components'
import MainGrid from '../styling/MainGrid'
import theme from '../styling/theme'
import VerticalPadding from '../styling/VerticalPadding'

interface Item {
  li: string
  p: string
}
interface IProjectsThatCount {
  tag?: string
  heading?: string
  liContent?: Array<Item>
}

const ProjectsThatCount = ({ tag, heading, liContent }: IProjectsThatCount) => {
  return (
    <Container>
      <VerticalPadding>
        <MainGrid>
          <TagContainer>
            {tag && <p className="section-tag">{tag}</p>}
          </TagContainer>
          <TextContainer>
            <Col>{heading && <h3>{heading}</h3>}</Col>
            <Col>
              <ul>
                {liContent &&
                  liContent.map((item, key) => {
                    return (
                      <ListItem key={Math.random()}>
                        {item.li && <li>{item.li}</li>}
                        {item.p && <p>{item.p}</p>}
                      </ListItem>
                    )
                  })}
              </ul>
            </Col>
          </TextContainer>
        </MainGrid>
      </VerticalPadding>
    </Container>
  )
}

export default ProjectsThatCount

const Container = styled.section``

const TagContainer = styled.div``
const TextContainer = styled.div`
  @media (min-width: 768px) {
    display: flex;
  }
`
const Col = styled.div`
  &:first-of-type {
    margin-bottom: 20px;
  }
  @media (min-width: 768px) {
    width: 50%;
    &:first-of-type {
      margin-right: 5rem;
      margin-bottom: 0px;
    }
  }
`
const ListItem = styled.div`
  margin-bottom: 15px;

  @media (min-width: 768px) {
    margin-bottom: 34px;
  }
  & p {
    padding-left: 34px;
  }
`
