import React from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import MainGrid from '../styling/MainGrid'
import Button from '../shared/Button/Button'
import SectionHeading from '../shared/SectionHeading'
import VerticalPadding from '../styling/VerticalPadding'

const GreenCards = () => {
  return (
    <Container>
      <VerticalPadding>
        <MainGrid noPaddingMobile>
          <HeadingContainer>
            <p className="section-tag">WHAT IS RESEARCH AND DEVELOPMENT?</p>
            <SectionHeading center>
              How you claim depends on the size of your company
            </SectionHeading>
          </HeadingContainer>
          <Grid>
            <CardGreen>
              <CardHeading white>
                <h3>
                  <b>Small and medium sized companies </b>(SME)
                </h3>
                <p>
                  The financial benefit on average will be 33% of the cost of
                  the R&D project.
                </p>
              </CardHeading>
              <Section white>
                <h6>Fewer than 500 members of staff</h6>
                <p className="p-large">
                  Even a company with a large workforce measuring in the
                  hundreds can be considered an SME in R&D terms.
                </p>
              </Section>
              <Section white>
                <h6>
                  Under €100m turnover or a balance sheet total under €86m
                </h6>
                <p className="p-large">
                  If a firm meets both criteria for its balance-sheet size and
                  its turnover, then it is considered an SME for R&D claims.
                </p>
              </Section>
            </CardGreen>
            <Card>
              <CardHeading>
                <h3>
                  <b>Research & Development Expenditure Credit</b> (RDEC)
                </h3>
                <p>
                  The large company scheme calculates claims at 12% of your
                  company’s qualifying R&D expenditure
                </p>
              </CardHeading>
              <Section>
                <h6>More than 500 members of staff</h6>
                <p className="p-large">
                  If you have a very large workforce of 500 employees or more,
                  your firm will automatically classify for an RDEC claim.
                </p>
              </Section>
              <Section>
                <h6>Large asset base</h6>
                <p className="p-large">
                  Companies classed as RDEC have balance sheets in excess of
                  £75m.
                </p>
              </Section>
            </Card>
          </Grid>
        </MainGrid>
      </VerticalPadding>
    </Container>
  )
}

export default GreenCards

const Container = styled.section``

const HeadingContainer = styled.div`
  width: 100%;
  padding: 0 20px;
  max-width: 918px;
  margin: 0 auto;
  text-align: center;
  @media (min-width: 768px) {
    padding: 0;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 30px;
  @media (min-width: 768px) {
    margin-top: 54px;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 3rem;
  }
`
const Card = styled.div`
  background: ${theme.background.hpHeader};
  padding: 47px 20px 40px;

  @media (min-width: 768px) {
    border-radius: 4px;
  }
  @media (min-width: 1024px) {
    padding: 80px 40px;
  }
  @media (min-width: 1200px) {
    padding: 80px 58px;
  }
`
const CardGreen = styled(Card)`
  background: ${theme.brand.blue};
  color: white;
`
interface ICardHeading {
  white?: boolean
}
const CardHeading = styled.div<ICardHeading>`
  & h3 {
    color: ${props => props.white && 'white'};
    font-weight: 300;
    font-family: Roboto;
    @media (min-width: 768px) {
      letter-spacing: -1.2px;
    }

    @media (min-width: 1200px) {
      letter-spacing: -1.54px;
    }
  }

  & p {
    color: ${props => props.white && 'rgba(255,255,255,0.8)'};
  }
  padding-bottom: 32px;
  border-bottom: 1px solid
    ${props => (props.white ? theme.grey.white80 : theme.grey.keyline)};

  @media (min-width: 768px) {
    text-align: left;
    padding-bottom: 47px;
  }
`

const Section = styled.div<ICardHeading>`
  padding: 35px 0;
  border-bottom: 1px solid
    ${props => (props.white ? theme.grey.white80 : theme.grey.keyline)};

  & p {
    color: ${props =>
      props.white ? 'rgba(255,255,255,0.8)' : theme.text.greyLight};
  }
  &:last-of-type {
    border-bottom: none;
    padding: 35px 0 0;
  }

  & h6 {
    font-weight: 700;
    margin-bottom: 8px;
    color: ${props => (props.white ? theme.text.white : theme.text.main)};
  }

  & .p-large {
  }
`
