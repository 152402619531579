import React from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import SectionHeading from '../shared/SectionHeading'
import VerticalPadding from '../styling/VerticalPadding'
import icon1 from '../../images/tax-credit/review.svg'
import icon2 from '../../images/tax-credit/laptop.svg'
import icon3 from '../../images/tax-credit/check.svg'
import icon4 from '../../images/tax-credit/clients.svg'
import icon5 from '../../images/tax-credit/communicate.svg'
import icon6 from '../../images/tax-credit/submit.svg'
import MainGrid from '../styling/MainGrid'

const data = [
  {
    icon: icon1,
    iconAlt: 'Review',
    heading: 'Undertake a final review',
    body:
      'Undertake a final review of the completed report before sending to client',
  },
  {
    icon: icon2,
    iconAlt: 'Laptop',
    heading: 'Prepare and submit',
    body:
      'Prepare tax computations and calculation in preparation for submission to HMRC',
  },
  {
    icon: icon3,
    iconAlt: 'Check',
    heading: 'Complete all checks',
    body: 'Complete final check of all documentation before submission to HMRC',
  },
  {
    icon: icon4,
    iconAlt: 'Clients',
    heading: 'Confim who is submitting',
    body: 'Confirm who is submitting the claim - us or the client’s accountant',
  },
  {
    icon: icon5,
    iconAlt: 'Communicate',
    heading: 'Communicate',
    body:
      'Communicate with the client and their accountant the expected result and value of the claim',
  },
  {
    icon: icon6,
    iconAlt: 'Submit',
    heading: 'Submit & follow up',
    body:
      'Submit the claim ourselves or follow up with the clients’ accountant if they are sending it to HMRC',
  },
]
const IconsGrid = () => {
  return (
    <Container>
      <VerticalPadding>
        <MainGrid>
          <HeadingContainer>
            <p className="section-tag">WE WILL DO ALL THE HARD WORK FOR YOU</p>
            <SectionHeading center>What we will do for you</SectionHeading>
          </HeadingContainer>
          <Grid>
            {data &&
              data.map((item, key) => {
                return (
                  <Card key={key}>
                    <Icon src={item.icon} alt={item.iconAlt} />
                    <CardHeading>{item.heading}</CardHeading>
                    <Body>{item.body}</Body>
                  </Card>
                )
              })}
          </Grid>
        </MainGrid>
      </VerticalPadding>
    </Container>
  )
}

export default IconsGrid

const Container = styled.section`
  padding: 20px 0 0;
`

const HeadingContainer = styled.div`
  width: 100%;
  max-width: 918px;
  margin: 0 auto;
  text-align: center;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 30px;
  grid-column-gap: 33px;
  grid-row-gap: 22px;
  @media (min-width: 768px) {
    margin-top: 54px;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 6rem;
    grid-row-gap: 6rem;
  }
`
const Card = styled.div``

const Icon = styled.img`
  height: 100%;
  max-height: 64px;
  display: block;
  margin: 0 auto 10px;

  @media (min-width: 768px) {
    margin-bottom: 14px;
  }
`

const Body = styled.p`
  text-align: center;
  margin-top: 12px;
`
const CardHeading = styled.h6`
  text-align: center;
  font-weight: bold;
  margin-bottom: 5px;
`
