import React, {
  useLayoutEffect,
  useEffect,
  useState,
  FunctionComponent,
} from 'react'
import styled from 'styled-components'
import InlineLink from '../shared/InlineLink'
import icon1 from '../../images/tax-credit/aerospace.svg'
import icon2 from '../../images/tax-credit/cctv.svg'
import icon3 from '../../images/tax-credit/paint.svg'
import icon4 from '../../images/tax-credit/construction.svg'
import icon5 from '../../images/tax-credit/electronics.svg'
import icon6 from '../../images/tax-credit/engineering.svg'
import icon7 from '../../images/tax-credit/materials.svg'
import icon8 from '../../images/tax-credit/projects.svg'
import icon9 from '../../images/tax-credit/sustainablity.svg'
import icon10 from '../../images/tax-credit/pharmaceutical.svg'
import icon11 from '../../images/tax-credit/testing.svg'
import arr from '../../images/arrCarousel.svg'
import arrHovered from '../../images/arrCarouselHovered.svg'
import theme from '../styling/theme'
import Cursor from '../Cursor'
import SectionHeading from '../shared/SectionHeading'
import VerticalPadding from '../styling/VerticalPadding'
import SwiperCore, { Navigation, Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/scrollbar/scrollbar.scss'

// install Swiper components
SwiperCore.use([Navigation, Scrollbar])

const data = [
  {
    img: icon1,
    companyName: 'Aerospace and Defence',
    content:
      'Do your projects involve high quality material finishes? Do you design bespoke products for the aerospace industry using different types of materials?',
    url: '/aerospace-and-defence',
  },
  {
    img: icon2,
    companyName: 'Alarms/CCTV',
    content:
      'Are you continually gaining new technical knowledge and experience on your projects?...',
    url: '/alarms',
  },
  {
    img: icon3,
    companyName: 'Chemicals, Paints and Adhesive',
    content:
      'Are you using new or unusual materials, design processes or manufacturing techniques on your products?',
    url: '/chemicals-paints-adhesive',
  },
  {
    img: icon4,
    companyName: 'Construction and Building Material',
    content:
      'Do you design bespoke and iconic features e.g. Facades, staircases, furniture or roofing systems? Are you using existing materials, design processes…',
    url: '/construction-and-building-material',
  },
  {
    img: icon5,
    companyName: 'Electronics and Electrical',
    content:
      'Are you using new or unusual materials design processes or manufacturing techniques on your products?',
    url: '/electronics-and-electrical',
  },
  {
    img: icon11,
    companyName: 'Testing',
    content:
      'Do your projects involve Business Information Modelling (BIM) or software testing, e.g. structural, thermal or wind software modelling?',
    url: '/testing',
  },
  {
    img: icon6,
    companyName: 'Engineering and Machinery Design',
    content:
      'Do your designs and products involve new or unusual processes, materials or systems? Are you manufacturing products to address new markets?',
    url: '/engineering-and-machinery-design',
  },
  {
    img: icon7,
    companyName: 'Materials, Finishes and Construction…',
    content:
      'Are you using existing materials, design processes or manufacturing techniques on your projects, but in a new or novel way?',
    url: '/materials-finishes-and-construction',
  },
  {
    img: icon8,
    companyName: 'Projects',
    content:
      'Are you working to develop new processes or methods? Are you making adaptations to existing software or designs?',
    url: '/projects',
  },
  {
    img: icon9,
    companyName: 'Sustainability',
    content:
      'Are you seeking sustainable certification on your projects, such as BREEAM, Passivhaus or LEED? Do you design or integrate sustainable systems into your projects?',
    url: '/sustainability',
  },
  {
    img: icon10,
    companyName: 'Pharmaceuticals, Biotechnology...',
    content:
      'Are you developing new drugs or administration methods? Are you investigating the structures and effects of viruses or bacteria? Are you working to explore how the body or mind responds to pathogens or other external stimuli?',
    url: '/pharmaceuticals-biotechnology-and-medical-science',
  },
]
const heading = 'How R&D might take place in your industry sector:'

interface QuProps {
  id: string
}
const QualifyingCarousel: FunctionComponent<QuProps> = ({ id }) => {
  const [slides, setSlides] = useState(3)
  const [padding, setPadding] = useState(25)
  const [charLimit, setCharLimit] = React.useState(160)
  const [headingLimit, setHeadingLimit] = React.useState(25)

  const formatText = (text: string) => {
    if (text.length < charLimit) {
      return text
    }
    return text.substr(0, charLimit) + '...'
  }

  const formatHeading = (text: string) => {
    if (text.length < headingLimit) {
      return text
    }
    return text.substr(0, headingLimit) + '...'
  }
  useLayoutEffect(() => {
    function updateSize() {
      if (typeof window !== 'undefined') {
        if (window.innerWidth <= 767) {
          setSlides(window.innerWidth / (320 + padding * 2.5))
        } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
          setSlides(window.innerWidth / (360 + padding * 2))
        } else if (window.innerWidth >= 1024 && window.innerWidth < 1200) {
          setSlides(window.innerWidth / (360 + padding * 2.5))
        } else {
          setSlides(3.05)
        }
      }
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return (
    <VerticalPadding>
      <Container>
        <Cursor id={id} />
        <HeadingContainer>
          <Heading center>{heading}</Heading>
        </HeadingContainer>
        <SwiperContainer>
          <Swiper
            className="swiper-container-qualifying"
            slidesPerView={slides}
            navigation
            scrollbar
          >
            <SwipperWrapper id={id} className="swiper-wrapper">
              {data.map((item, i) => {
                return (
                  <SwiperSlide key={i} className="carousel-drag-slide">
                    <ImageContainer>
                      <Image src={item.img} />
                    </ImageContainer>
                    <CompanyName>{item.companyName}</CompanyName>
                    {item.content && (
                      <Content className="p-large">
                        {formatText(item.content)}
                      </Content>
                    )}
                    {item.url && (
                      <InlineLink
                        to={
                          (process.env.BASE === '/' ? '' : process.env.BASE) +
                          item.url
                        }
                        text={'Read more'}
                        icon
                        margintop="auto"
                        margintopDesktop="auto"
                      />
                    )}
                  </SwiperSlide>
                )
              })}
            </SwipperWrapper>
          </Swiper>
        </SwiperContainer>
      </Container>
      <InlineLinkWrapper>
        <InlineLink
          text="View all industry sectors"
          icon
          to={
            (process.env.BASE === '/' ? '' : process.env.BASE) +
            '/industry-sector'
          }
        />
      </InlineLinkWrapper>
    </VerticalPadding>
  )
}

export default QualifyingCarousel

const Container = styled.section`
  padding: 25px 20px 50px;
  overflow: hidden;
  @media (min-width: 768px) {
    padding: 0px 20px 50px;
  }
  @media (min-width: 1024px) {
    padding: 0px 3% 50px;
  }
  @media (min-width: 1200px) {
    padding: 0px 0 50px;
  }
  .swiper-container-qualifying {
    overflow: visible;
    transition: transform 0.8s ease;
    width: 100%;
    margin-right: auto;
    transition: transform 0.4s ease;
    @media (min-width: 1024px) {
      width: 100%;
    }
  }
  .carousel-drag-slide {
    padding: 46px 30px 72px;
    background: white;
    width: 320px !important;
    min-width: 320px !important;
    height: auto;
    transition: transform 1s ease;
    margin-right: 20px;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    @media (min-width: 768px) {
      padding: 55px 40px 44px;
      width: 360px !important;
      min-width: 360px !important;
    }
  }

  .swiper-scrollbar {
    position: absolute;
    height: 1px;
    width: calc(100vw - 160px);
    background: ${theme.grey.scroll};
    left: 100px;
    bottom: -40px;
    @media screen and (min-width: 1024px) {
      width: calc(100vw - 6% - 100px);
    }
    @media screen and (min-width: 1200px) {
      width: 92%;
    }
  }
  .swiper-scrollbar-drag {
    height: 3px;
    position: absolute;
    top: -1px;
    background: ${theme.brand.blue};
  }

  .swiper-button-next {
    margin-left: 18px;
    left: 15px;
  }

  .swiper-button-prev {
    transform: rotate(-180deg);
    left: 0;
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    opacity: 0;
  }
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: auto;
    bottom: -50px;
    width: 4.9rem;
    height: 1rem;
    margin: 0;
  }
  .swiper-button-next {
    left: 50px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    background: url(${arr});
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    width: 33px;
    height: 20px;
  }
  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    background: url(${arrHovered});
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    width: 33px;
    height: 20px;
  }
  .swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }
`
const Heading = styled.p`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.62px;
  line-height: 26px;
  color: ${theme.text.main};
  flex: 1;
  @media (min-width: 1024px) {
    font-size: 20px;
    letter-spacing: -0.77px;
    line-height: 30px;
  }
`

const HeadingContainer = styled.div`
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    margin-bottom: 24px;
  }
`

const SwiperContainer = styled.div`
  cursor: grab;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  position: relative;
`
const SwipperWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
`
const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 22px;
`
const CompanyName = styled.h6`
  font-weight: bold;
  height: 60px;
  @media (min-width: 1024px) {
    margin-bottom: 9px;
  }
`

const Image = styled.img`
  height: 43px;
  width: 50px;
`

const Content = styled.p`
  display: block;
  margin-bottom: 40px;
`

const InlineLinkWrapper = styled.div`
  margin-top: 22px;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  @media (min-width: 1024px) {
    margin-top: 47px;
  }
`
